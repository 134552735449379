import React from "react"
import { ThemeProvider } from "theme-ui"
import { Global } from "@emotion/core"
import Theme from "../particles/Theme"
import Header from "../organisms/header/Header"
import GlobalStyle from "../particles/GlobalStyle"
import Footer from "../organisms/footer/Footer"
import Seo from "../seo"

import "normalize.css"
import "../particles/styles/styles.scss"
import EmergencyBanner from "../organisms/emergencyBanner/EmergencyBanner"

const Base = ({ children, title }) => {
  return (
    <ThemeProvider theme={Theme}>
      <Global styles={GlobalStyle} />
      <Seo title={title} />
      {/* <EmergencyBanner /> */}
      <Header />
      <main className="site-content">{children}</main>
      <Footer
        style={{
          marginTop: title === "Home" || title === "Contact Us" ? `0px` : undefined,
        }}
      />
    </ThemeProvider>
  )
}

export default Base
