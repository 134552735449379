import { css } from "@emotion/core"
import theme from "../../particles/Theme"

export const StyledCard = props => css`
  display: inline-flex;
  cursor: pointer;
  position: relative;
  z-index: 1;
  height: 200px;
  width: 100%;
  color: white;
  transition: all 0.2s ease;

  &:hover {
    color: white;
  }

  h4 {
    align-self: flex-end;
    text-transform: uppercase;
    margin: 0;
    padding: 2rem;
    position: relative;
  }

  h4::before {
    content: "";
    height: 15px;
    background: ${theme.colors.primary}b3;
    width: 0;
    position: absolute;
    left: 0;
    top: 4rem;
    transition: all 0.2s ease;
    z-index: -100;
  }

  &:hover h4::before {
    width: ${props}px;
  }

  &.no-image {
    background: ${theme.colors.tertiary};
    color: ${theme.colors.bodyFont};

    &:hover {
      background: ${theme.colors.bodyFont};
      color: ${theme.colors.tertiary};
    }
  }
`

export const CardHover = css`
  &::before,
  &::after {
    transition: opacity 0.5s ease 500ms, filter 0.2s ease !important;
  }

  &:hover::before,
  &:hover::after {
    filter: grayscale(80%);
  }
`
