import React from "react"
import StyledBreadcrumbs from "./Breadcrumbs.style"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { randomID } from "../../particles/helpers"
import theme from "../../particles/Theme"
import Content from "../../particles/Content"
import useRelative from "../../particles/hooks/useRelative"

const Breadcrumbs = () => {
  let url = []

  if (typeof window !== `undefined`) {
    url = window.location.pathname.split("/").slice(1)
  }
  return (
    <StyledBreadcrumbs>
      <Content className="content">
        <ul>
          <li key={randomID()}>
            <Link to={useRelative("/")}>HOME</Link>
          </li>
          {url
            .filter(url => url !== "")
            .map((breadcrumb, idx) => (
              <li key={randomID()}>
                <Link
                  to={useRelative(`/` + url.slice(0, idx + 1).join("/") + `/`)}
                >
                  {breadcrumb.replace(/-|_/g, " ").toUpperCase()}
                </Link>
              </li>
            ))}
        </ul>
      </Content>
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
