import React, { useState, useEffect, useRef } from "react"
import StyledEmergencyBanner from "./EmergencyBanner.style"
import Content from "../../particles/Content"
import { css } from "@emotion/core"
import theme from "../../particles/Theme"
import { Link } from "gatsby"

const Exit = css`
  content: "X";
  position: absolute;
  top: 0;
  right: 0;
  color: ${theme.colors.primary};
  font-weight: 700;
  padding: 2rem;
  font-size: 2rem;
  cursor: pointer;
`

const EmergencyBanner = () => {
  const [isDisplayed, setDisplayed] = useState(true)
  const banner = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (banner) {
      function addBanner() {
        let bannerHeight = getComputedStyle(document.querySelector('.emergency-banner')).height

        isDisplayed
          ? (document.querySelector("body").style.paddingTop = bannerHeight)
          : (document.querySelector("body").style.paddingTop = `initial`)
      }

      window.addEventListener("resize", addBanner)

      addBanner()
    }
  })

    return (
      <StyledEmergencyBanner
        ref={banner}
        className="emergency-banner"
      >
        <Content>
          <Link to="/blogs/covid-19-update">
            COVID-19 ANNOUNCEMENT: Initiatives DIXIEPLY is implementing to
            mitigate the spread of the Coronavirus.
          </Link>
        </Content>
        {/* <section css={Exit} onClick={() => setDisplayed(false)}>
          X
        </section> */}
      </StyledEmergencyBanner>
    )
}

export default EmergencyBanner
