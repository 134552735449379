import styled from "@emotion/styled"
import theme from "../../particles/Theme"

const StyledBreadcrumbs = styled.div`
  height: 50px;
  background: white;
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breakpoints[0]}) {
    display: none;
  }

  .content {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececef;
    height: 100%;
    ul {
      padding: 0;
      li {
        list-style: none;
        display: inline;
        padding-right: 4rem;
        position: relative;

        a {
          color: ${theme.colors.secondary};
          transition: all 0.2s ease;
          &:hover {
            color: ${theme.colors.secondaryHover};
            text-decoration: underline;
          }
        }

        &:not(:last-child):after {
          content: "";
          position: absolute;
          right: 1.25rem;
          top: calc(50% - 0.9rem);
          width: 0;
          height: 0;
          border-bottom: 8px solid transparent;
          border-top: 8px solid transparent;
          border-left: 12px solid ${theme.colors.primary};
        }
      }
    }
  }
`

export default StyledBreadcrumbs
