import { css } from "@emotion/core"
import theme from "../../particles/Theme"

const StyledBanner = css`
  height: 175px;
  display: flex;
  align-items: center;
  color: white;

  h2 {
    margin: 0;

    @media (max-width: ${theme.breakpoints[0]}) {
      font-size: 4rem;
      line-height: 1.2em;
    }
  }
`

export default StyledBanner
