import React, { useContext } from "react"

import Base from "./Base"
import Card from "../molecules/Card/Card"
import Content from "../particles/Content"
import styled from "@emotion/styled"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import Banner from "../organisms/banner/Banner"
import theme from "../particles/Theme"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { randomID } from "../particles/helpers"

const StyledProductCards = styled.div`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  min-height: calc(100vh - 181px - 218px - 5rem);
  grid-auto-rows: min-content;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
  }
`

const ProductSearch = ({ pageContext }) => {
  const state = useContext(GlobalStateContext)

  let pageCategories = []
  let url

  if (typeof window !== `undefined`) {
    let branches = pageContext.branches
    let commercialProducts, residentialProducts

    branches.forEach(branch => {
      if (state && branch.title === state.branch) {
        commercialProducts = []
        residentialProducts = []


        if (branch.productSelection.residentialProducts) {
          let residential = Object.values(
            branch.productSelection.residentialProducts
          )

          residential.forEach(product => {
            if (
              product.products ||
              product.vendors ||
              (product.subcat1.subcatname &&
                (product.subcat1.products ||
                  product.subcat1.vendors ||
                  (product.subcat1.subcat1.subcatname &&
                    (product.subcat1.subcat1.products ||
                      product.subcat1.subcat1.vendors ||
                      (
                        product.subcat1.subcat1.subcatname &&
                        (product.subcat1.subcat1.products ||
                          product.subcat1.subcat1.vendors)
                      )(
                        product.subcat1.subcat2.subcatname &&
                          (product.subcat1.subcat2.products ||
                            product.subcat1.subcat2.vendors)
                      )(
                        product.subcat1.subcat3.subcatname &&
                          (product.subcat1.subcat3.products ||
                            product.subcat1.subcat3.vendors)
                      ))) ||
                  (product.subcat2.subcat1.subcatname &&
                    (product.subcat2.subcat1.products ||
                      product.subcat2.subcat1.vendors ||
                      (
                        product.subcat2.subcat1.subcatname &&
                        (product.subcat2.subcat1.products ||
                          product.subcat2.subcat1.vendors)
                      )(
                        product.subcat2.subcat2.subcatname &&
                          (product.subcat2.subcat2.products ||
                            product.subcat2.subcat2.vendors)
                      )(
                        product.subcat2.subcat3.subcatname &&
                          (product.subcat2.subcat3.products ||
                            product.subcat2.subcat3.vendors)
                      ))) ||
                  (product.subcat3.subcat1.subcatname &&
                    (product.subcat3.subcat1.products ||
                      product.subcat3.subcat1.vendors ||
                      (
                        product.subcat3.subcat1.subcatname &&
                        (product.subcat3.subcat1.products ||
                          product.subcat3.subcat1.vendors)
                      )(
                        product.subcat3.subcat2.subcatname &&
                          (product.subcat3.subcat2.products ||
                            product.subcat3.subcat2.vendors)
                      )(
                        product.subcat3.subcat3.subcatname &&
                          (product.subcat3.subcat3.products ||
                            product.subcat3.subcat3.vendors)
                      ))) ||
                  (product.subcat4.subcat1.subcatname &&
                    (product.subcat4.subcat1.products ||
                      product.subcat4.subcat1.vendors ||
                      (
                        product.subcat4.subcat1.subcatname &&
                        (product.subcat4.subcat1.products ||
                          product.subcat4.subcat1.vendors)
                      )(
                        product.subcat4.subcat2.subcatname &&
                          (product.subcat4.subcat2.products ||
                            product.subcat4.subcat2.vendors)
                      )(
                        product.subcat4.subcat3.subcatname &&
                          (product.subcat4.subcat3.products ||
                            product.subcat4.subcat3.vendors)
                      ))) ||
                  (product.subcat5.subcat1.subcatname &&
                    (product.subcat5.subcat1.products ||
                      product.subcat5.subcat1.vendors ||
                      (
                        product.subcat5.subcat1.subcatname &&
                        (product.subcat5.subcat1.products ||
                          product.subcat5.subcat1.vendors)
                      )(
                        product.subcat5.subcat2.subcatname &&
                          (product.subcat5.subcat2.products ||
                            product.subcat5.subcat2.vendors)
                      )(
                        product.subcat5.subcat3.subcatname &&
                          (product.subcat5.subcat3.products ||
                            product.subcat5.subcat3.vendors)
                      )))))
            )
              residentialProducts.push(product.catname)
          })
        }

        if (branch.productSelection.commercialProducts) {
          let commercial = Object.values(
            branch.productSelection.commercialProducts
          )

           commercial.forEach(product => {
             if (
               product.products ||
               product.vendors ||
               (product.subcat1.subcatname &&
                 (product.subcat1.products ||
                   product.subcat1.vendors ||
                   (product.subcat1.subcat1.subcatname &&
                     (product.subcat1.subcat1.products ||
                       product.subcat1.subcat1.vendors ||
                       (
                         product.subcat1.subcat1.subcatname &&
                         (product.subcat1.subcat1.products ||
                           product.subcat1.subcat1.vendors)
                       )(
                         product.subcat1.subcat2.subcatname &&
                           (product.subcat1.subcat2.products ||
                             product.subcat1.subcat2.vendors)
                       )(
                         product.subcat1.subcat3.subcatname &&
                           (product.subcat1.subcat3.products ||
                             product.subcat1.subcat3.vendors)
                       ))) ||
                   (product.subcat2.subcat1.subcatname &&
                     (product.subcat2.subcat1.products ||
                       product.subcat2.subcat1.vendors ||
                       (
                         product.subcat2.subcat1.subcatname &&
                         (product.subcat2.subcat1.products ||
                           product.subcat2.subcat1.vendors)
                       )(
                         product.subcat2.subcat2.subcatname &&
                           (product.subcat2.subcat2.products ||
                             product.subcat2.subcat2.vendors)
                       )(
                         product.subcat2.subcat3.subcatname &&
                           (product.subcat2.subcat3.products ||
                             product.subcat2.subcat3.vendors)
                       ))) ||
                   (product.subcat3.subcat1.subcatname &&
                     (product.subcat3.subcat1.products ||
                       product.subcat3.subcat1.vendors ||
                       (
                         product.subcat3.subcat1.subcatname &&
                         (product.subcat3.subcat1.products ||
                           product.subcat3.subcat1.vendors)
                       )(
                         product.subcat3.subcat2.subcatname &&
                           (product.subcat3.subcat2.products ||
                             product.subcat3.subcat2.vendors)
                       )(
                         product.subcat3.subcat3.subcatname &&
                           (product.subcat3.subcat3.products ||
                             product.subcat3.subcat3.vendors)
                       ))) ||
                   (product.subcat4.subcat1.subcatname &&
                     (product.subcat4.subcat1.products ||
                       product.subcat4.subcat1.vendors ||
                       (
                         product.subcat4.subcat1.subcatname &&
                         (product.subcat4.subcat1.products ||
                           product.subcat4.subcat1.vendors)
                       )(
                         product.subcat4.subcat2.subcatname &&
                           (product.subcat4.subcat2.products ||
                             product.subcat4.subcat2.vendors)
                       )(
                         product.subcat4.subcat3.subcatname &&
                           (product.subcat4.subcat3.products ||
                             product.subcat4.subcat3.vendors)
                       ))) ||
                   (product.subcat5.subcat1.subcatname &&
                     (product.subcat5.subcat1.products ||
                       product.subcat5.subcat1.vendors ||
                       (
                         product.subcat5.subcat1.subcatname &&
                         (product.subcat5.subcat1.products ||
                           product.subcat5.subcat1.vendors)
                       )(
                         product.subcat5.subcat2.subcatname &&
                           (product.subcat5.subcat2.products ||
                             product.subcat5.subcat2.vendors)
                       )(
                         product.subcat5.subcat3.subcatname &&
                           (product.subcat5.subcat3.products ||
                             product.subcat5.subcat3.vendors)
                       )))))
             )
               commercialProducts.push(product.catname)
           })
        }




      }
    })

    url = window.location.pathname
    const [superCategory, isInterior, subLevel] = url.split("/").slice(2, 5)

    if (isInterior === "interior" && !subLevel) {
      Object.values(pageContext).forEach(product => {
        if (
          product.Residential &&
          product.Residential.isInterior
        ) {
          if (residentialProducts && ! residentialProducts.includes(product.title))
            return null
          else
            pageCategories.push(product)
        }
      })
    } else if (isInterior === "exterior" && !subLevel) {
      Object.values(pageContext).forEach(product => {
        if (
          product.Residential &&
          !product.Residential.isInterior
        ) {
          if (residentialProducts && ! residentialProducts.includes(product.title))
            return null
          else
            pageCategories.push(product)
        }
      })
    } else if (superCategory === "commercial") {
      Object.values(pageContext).forEach(product => {
        if (product.Commercial) {
          if (commercialProducts && ! commercialProducts.includes(product.title))
            return null
          else
            pageCategories.push(product)
        }
      })
    }
  }

  if (url && url.slice(-1) !== "/") {
    url = url + "/"
  }

  return (
    <Base title={pageContext.title}>
      <Banner title={pageContext.title} />
      <Breadcrumbs />
      <Content>
        <StyledProductCards>
          {pageCategories
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map(category => {
              return (
                <Card
                  key={randomID()}
                  title={category.title}
                  link={url + category.slug}
                  backgroundImage={category.featuredImage.node || undefined}
                />
              )
            })}
        </StyledProductCards>
      </Content>
    </Base>
  )
}

export default ProductSearch
