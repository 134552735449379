import { useStaticQuery, graphql } from "gatsby"

export const useBannerImage = () => {
  const wordpress = useStaticQuery(graphql`query getBanner {
    wp {
      themeOptions {
        generalSettings {
          bannerBackground {
            altText
            uri
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`)

  return wordpress.wp.themeOptions.generalSettings.bannerBackground
}
