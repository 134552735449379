import styled from "@emotion/styled"
import theme from "../../particles/Theme"

const StyledEmergencyBanner = styled.div`
  color: ${theme.colors.primary};
  position: fixed;
  text-align: center;
  top: 0px;
  left: 0;
  width: 100%;
  height: 75px;
  background: white;
  z-index: 10000;
  box-shadow: 0px 0px 10px ${theme.colors.secondary};
  transition: top 0.5s ease-in-out;

  @media screen and (max-width: ${theme.breakpoints[1]}) {
    top: 0;
    height: 100px;
  }

  div {
    position: relative;
    top: 25px;
    width: 82%;

    @media screen and (max-width: ${theme.breakpoints[0]}) {
      width: 85%;
    }

    a:hover {
      text-decoration: underline;
    }
  }


`

export default StyledEmergencyBanner
