import React from "react"
import Content from "../../particles/Content"
import { useBannerImage } from "../../particles/hooks/useBannerImage"
import StyledBanner from "./Banner.style"
import BackgroundImage from "gatsby-background-image"
import theme from "../../particles/Theme"
import { convertToBgImage } from "gbimage-bridge";

const Banner = ({ title }: BannerProps) => {
  let bannerImage = useBannerImage()

  const backgroundStack = [
    bannerImage.localFile.childImageSharp.gatsbyImageData,
    `linear-gradient( ${theme.colors.secondaryHover}b3, ${theme.colors.secondaryHover}b3) `,
  ].reverse()

  const bgImage = convertToBgImage(backgroundStack);

  return (
    <BackgroundImage Tag="div" css={StyledBanner} {...bgImage}>
      <Content>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      </Content>
    </BackgroundImage>
  )
}

type BannerProps = {
  title: string
}

export default Banner
