import React, { useRef, useLayoutEffect, useState } from "react"
import { StyledCard, CardHover } from "./Card.style"
import { Link } from "gatsby"
import hexToRgba from "hex-to-rgba"
import BackgroundImage from "gatsby-background-image"
import theme from "../../particles/Theme"
import { convertToBgImage } from "gbimage-bridge";


const Card = ({ title, link, backgroundImage }: CardProps) => {
  if (backgroundImage) {
    const imageData = backgroundImage.localFile.childImageSharp.gatsbyImageData
    const backgroundStack = [
      imageData,
      `linear-gradient(to top left, ${hexToRgba(
        theme.colors.secondaryHover + `b3`
      )}, ${hexToRgba(theme.colors.secondary + `b3`)})`,
    ].reverse()

    const targetRef = useRef<HTMLHeadingElement>()
    const [width, setWidth] = useState(0)

    useLayoutEffect(() => {
      if (targetRef.current) {
        setWidth(targetRef.current.offsetWidth)
      }
    })

  const bgImage = convertToBgImage(backgroundStack);

    return (
      <BackgroundImage Tag="div" css={CardHover} {...bgImage}>
        <Link to={link} css={StyledCard(width || 150)}>
          <h4
            ref={targetRef}
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </Link>
      </BackgroundImage>
    )
  } else {
    return (
      <Link to={link} css={StyledCard} className="no-image">
        <h4 dangerouslySetInnerHTML={{ __html: `${title}` }} />
      </Link>
    )
  }
}

type CardProps = {
  title: string
  link: string
  backgroundImage?: MediaProps
}

type MediaProps = {
    altText: string
    localFile: {
      childImageSharp: {
        gatsbyImageData
      }
    }
}

export default Card
